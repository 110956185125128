import * as React from "react"
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"


const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const headingStyles = {
  marginTop: 0,
  marginBottom: 48,
}

const paragraphStyles = {
  marginBottom: 48,
}


const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Nicht gefunden</title>
      </Helmet>
      <h1 style={headingStyles}>Seite wurde nicht gefunden</h1>
      <p style={paragraphStyles}>
        Entschuldigung{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        Leider haben wir nicht gefunden, wonach du suchst.
        <br />
        <br />
        <Link to="/">Zur Startseite</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage
